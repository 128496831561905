import React from "react";
import {Link} from "react-router-dom";

const Footer: React.FC = () => {
    return (
        <footer className="footer mt-auto py-3 bg-light">
            <div className="container">
                <span className="text-muted">
                    <Link to="/privacybeleid">Privacybeleid</Link>
                </span>
            </div>
        </footer>
    );
};

export default Footer;