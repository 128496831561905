import {calculateRentAllowance} from "./RentAllowanceCalculations";
import {calculateHealthcareAllowance} from "./HealthAllowanceCalculations";

export interface IncomeData {
    grossYearlyIncome: number;
    grossTax: number;
    generalTaxCredit: number;
    laborTaxCredit: number;
    unusedTaxCredit: number;
    netTax: number;
    netIncomeWithoutAllowance: number;
    healthcareAllowance?: number;
    rentAllowance?: number;
    netAllowance: number;
    netIncome: number;

    grossYearlyIncomeGain?: number;
    netIncomeWithoutAllowanceGain?: number;
    netIncomeGain?: number;
}

export interface IncomeFormData {
    grossYearlyIncome: number;
    grossYearlyIncomePartner: number;
    allowancePartner: boolean;
    housingData: HousingData;
}

export interface HousingData {
    type: HousingType;
    age: number;
    rent: number;
    numberOfPeopleInHouseHold: number;
    eligible: boolean;
}

export type HousingType = 'rent' | 'mortgage' | 'neither';


export const calculateIncomeData = (incomeFormData: IncomeFormData): IncomeData => {
    const grossYearlyIncome = incomeFormData.grossYearlyIncome;
    const grossTax = calculateGrossTax(grossYearlyIncome);
    const generalTaxCredit = calculateGeneralTaxCredit(grossYearlyIncome);
    const laborTaxCredit = calculateLaborTaxCredit(grossYearlyIncome);
    const taxWithTaxCredit = grossTax - generalTaxCredit - laborTaxCredit;


    let netTax = 0;
    let unusedTaxCredit = 0;
    if (taxWithTaxCredit < 0) {
        unusedTaxCredit = taxWithTaxCredit * -1;
    } else {
        netTax = taxWithTaxCredit;
    }

    const netIncomeWithoutAllowance = grossYearlyIncome - netTax;

    let incomeIncludingPartner = grossYearlyIncome;

    if (incomeFormData.allowancePartner) {
        incomeIncludingPartner += incomeFormData.grossYearlyIncomePartner;
    }

    const healthcareAllowance = calculateHealthcareAllowance(incomeIncludingPartner, incomeFormData.allowancePartner);
    let rentAllowance = 0;

    const housingType = incomeFormData.housingData?.type;
    const rent = incomeFormData.housingData?.rent;
    const age = incomeFormData.housingData?.age;
    const numberOfPeopleInHouseHold = incomeFormData.housingData?.numberOfPeopleInHouseHold;

    if (housingType === 'rent' && rent && age && numberOfPeopleInHouseHold) {
        rentAllowance = calculateRentAllowance(rent, age, incomeIncludingPartner, numberOfPeopleInHouseHold);
    }


    const netAllowance = healthcareAllowance + rentAllowance;
    const netIncome = netIncomeWithoutAllowance + netAllowance;
    return {
        grossYearlyIncome,
        grossTax,
        generalTaxCredit,
        laborTaxCredit,
        netTax,
        unusedTaxCredit,
        netIncomeWithoutAllowance,
        healthcareAllowance,
        rentAllowance,
        netAllowance,
        netIncome
    };
}


const calculateGrossTax = (income: number) => {
    if (income <= 73031) {
        return income * 0.3693;
    } else {
        return (73031 * 0.3693) + ((income - 73031) * 0.495);
    }
};

const calculateGeneralTaxCredit = (income: number) => {
    if (income <= 22661) {
        return 3070;
    } else if (income <= 73031) {
        return 3070 - 0.06095 * (income - 22660);
    } else {
        return 0;
    }
};

const calculateLaborTaxCredit = (income: number) => {
    if (income <= 10741) {
        return income * 0.08231;
    } else if (income <= 23201) {
        return 884 + 0.29861 * (income - 10740);
    } else if (income <= 37691) {
        return 4605 + 0.03085 * (income - 23201);
    } else if (income <= 115295) {
        return 5052 - 0.06510 * (income - 37691);
    }

    return 0;
};




const factorA = 0.000000474433;
const factorB = 0.002448638402;
const rekeninkomen2 = 19375; // Minimuminkomensgrens
const taakstellingsbedrag = 0;

export const calculateMinimumRent = (rekeninkomen: number): number => {
    const basishuur = (factorA * rekeninkomen2) + (factorB * rekeninkomen) + taakstellingsbedrag;
    const minimumBasishuur = 225.54;

    return Math.max(basishuur, minimumBasishuur);
};

