import React from 'react';
import {Line} from 'react-chartjs-2';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import incomeDataSet from '../assets/incomeDataSet.json';
import {IncomeData} from "../utils/Calculations";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const incomeData: IncomeData[] = incomeDataSet as IncomeData[];

const BrutoNettoGraph = () => {
    // Dummy data for demonstration purposes
    const grossYearlyIncome = incomeData.map(income => income.grossYearlyIncome);
    const netIncomeWithoutAllowance = incomeData.map(income => income.netIncomeWithoutAllowance);
    const netIncome = incomeData.map(income => income.netIncome);

    const data = {
        labels: grossYearlyIncome.map(income => `€${income}`),
        datasets: [
            {
                label: 'Bruto Inkomen',
                data: grossYearlyIncome,
                borderColor: 'rgba(75, 192, 192, 1)',
                fill: false,
            },
            {
                label: 'Netto Inkomen (excl. toeslagen)',
                data: netIncomeWithoutAllowance,
                borderColor: 'rgba(255, 99, 132, 1)',
                fill: false,
            },
            {
                label: 'Netto Inkomen (incl. toeslagen)',
                data: netIncome,
                borderColor: 'rgba(44, 255, 44, 1)',
                fill: false,
            }
        ],
    };

    const isMobile = window.innerWidth <= 1024;

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            x: {
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: isMobile ? 4 : 20
                },
            },
            // ... other options
        },
        legend: {
            display: false, // Hide on mobile
        },
        plugins: {
            legend: {
                position: 'top' as const,
                display: !isMobile,
            },
            title: {
                display: false,
            },
        },
    };

    return (
        <div className="mb-5">
            <h2>Bruto vs Netto Inkomen</h2>
            <Line data={data} options={options}/>
        </div>
    );
};

export default BrutoNettoGraph;
