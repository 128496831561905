import React, {ChangeEvent, useEffect} from "react";
import {maxRent23AndOver, maxRent23Under} from "../utils/RentAllowanceCalculations";
import {HousingData, HousingType} from "../utils/Calculations";
import {removeTrailingNumber} from "../utils/Utils";


interface Props {
    housingData: HousingData;
    setHousingData: (data: HousingData) => void;
    index: number;
}

const HousingForm: React.FC<Props> = (props) => {
    const checkEligibilityForRentAllowance = (rentAmount: number, age: number) => {
        return age >= 23 ? rentAmount <= maxRent23AndOver : rentAmount <= maxRent23Under;
    }

    useEffect(() => {
        if (props.housingData.rent !== null && props.housingData.age !== null) {
            const eligible = checkEligibilityForRentAllowance(props.housingData.rent, props.housingData.age);
            props.setHousingData({
                ...props.housingData, eligible
            });
        }
    }, [props.housingData.rent, props.housingData.age]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        let {id, value} = e.target;
        id = removeTrailingNumber(id).replace(/^rt-/, '');
        props.setHousingData({...props.housingData, [id]: value});
    };

    const handleHousingTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const housingType = e.target.value as HousingType;
        props.setHousingData({...props.housingData, type: housingType});
    }

    return (
        <div className="mb-3">
            <div className="mb-3 w-100">
                <label className="form-label">Betaald u voor uw huisvesting?</label>
                <div className="d-flex gap-2 gap-md-3 flex-wrap">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            id={`rentOption-${props.index}`}
                            value="rent"
                            checked={props.housingData.type === 'rent'}
                            onChange={handleHousingTypeChange}
                        />
                        <label className="form-check-label" htmlFor={`rentOption-${props.index}`}>
                            Huur
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            value="mortgage"
                            id={`mortgageOption-${props.index}`}
                            checked={props.housingData.type === 'mortgage'}
                            onChange={handleHousingTypeChange}
                        />
                        <label className="form-check-label" htmlFor={`mortgageOption-${props.index}`}>
                            Hypotheek
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            value="neither"
                            id={`neitherOption-${props.index}`}
                            checked={props.housingData.type === 'neither'}
                            onChange={handleHousingTypeChange}
                        />
                        <label className="form-check-label" htmlFor={`neitherOption-${props.index}`}>
                            Geen van beide
                        </label>
                    </div>
                </div>
            </div>

            {props.housingData.type === 'rent' &&
                <div className="mb-3">
                    <div className="mb-3">
                        <label htmlFor={`rentAge-${props.index}`} className="form-label">Uw leeftijd</label>
                        <input type="number" className="form-control" id={`rt-age-${props.index}`}
                               value={props.housingData.age}
                               onChange={handleInputChange}/>
                    </div>

                    <div className="mb-3">
                        <label htmlFor={`rentAmount-${props.index}`} className="form-label">Huur</label>
                        <div className="input-group mb-3">
                            <span className="input-group-text">€</span>
                            <input type="number" className="form-control" id={`rt-rent-${props.index}`}
                                   value={props.housingData.rent}
                                   onChange={handleInputChange}/>
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor={`rentNumberOfPeopleInHouseHold-${props.index}`} className="form-label">Aantal
                            bewoners (incl. minderjarigen)</label>
                        <input type="number" className="form-control" onChange={handleInputChange}
                               value={props.housingData.numberOfPeopleInHouseHold}
                               id={`rt-numberOfPeopleInHouseHold-${props.index}`}/>
                    </div>

                    {!props.housingData.eligible && (
                        <div className="alert alert-warning" role="alert">
                            De maximale huur, voor uw leeftijd, om in aanmerking te komen voor huurtoeslag is:
                            €{props.housingData.age >= 23 ? maxRent23AndOver : maxRent23Under}
                        </div>
                    )}
                </div>
            }
        </div>
    );
}

export default HousingForm;