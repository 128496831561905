import React from "react";
import {IncomeData} from "../utils/Calculations";
import IncomeDataColumn, {ColumnData} from "./IncomeDataColumn";

interface Props {
    incomeData1: IncomeData;
    incomeData2: IncomeData;
    incomeDataLabels: ColumnData;
}

const CompareIncomeDataComponent: React.FC<Props> = (props) => {

    const compareIncomeData = {
        grossYearlyIncome: props.incomeData2.grossYearlyIncome - props.incomeData1.grossYearlyIncome,
        grossTax: props.incomeData2.grossTax - props.incomeData1.grossTax,
        generalTaxCredit: props.incomeData2.generalTaxCredit - props.incomeData1.generalTaxCredit,
        laborTaxCredit: props.incomeData2.laborTaxCredit - props.incomeData1.laborTaxCredit,
        unusedTaxCredit: props.incomeData2.unusedTaxCredit - props.incomeData1.unusedTaxCredit,
        netTax: props.incomeData2.netTax - props.incomeData1.netTax,
        netIncomeWithoutAllowance: props.incomeData2.netIncomeWithoutAllowance - props.incomeData1.netIncomeWithoutAllowance,
        healthcareAllowance: (props.incomeData2.healthcareAllowance ?? 0) - (props.incomeData1.healthcareAllowance ?? 0),
        rentAllowance: (props.incomeData2.rentAllowance ?? 0) - (props.incomeData1.rentAllowance ?? 0),
        netAllowance: props.incomeData2.netAllowance - props.incomeData1.netAllowance,
        netIncome: props.incomeData2.netIncome - props.incomeData1.netIncome,
    }

    const compareIncomeDataPercentage = {
        grossYearlyIncome: compareIncomeData.grossYearlyIncome / props.incomeData1.grossYearlyIncome,
        grossTax: compareIncomeData.grossTax / props.incomeData1.grossTax,
        generalTaxCredit: compareIncomeData.generalTaxCredit / props.incomeData1.generalTaxCredit,
        laborTaxCredit: compareIncomeData.laborTaxCredit / props.incomeData1.laborTaxCredit,
        unusedTaxCredit: compareIncomeData.unusedTaxCredit / props.incomeData1.unusedTaxCredit,
        netTax: compareIncomeData.netTax / props.incomeData1.netTax,
        netIncomeWithoutAllowance: compareIncomeData.netIncomeWithoutAllowance / props.incomeData1.netIncomeWithoutAllowance,
        healthcareAllowance: (compareIncomeData.healthcareAllowance ?? 0) / (props.incomeData1.healthcareAllowance ?? 0),
        rentAllowance: compareIncomeData.rentAllowance / props.incomeData1.rentAllowance!,
        netAllowance: compareIncomeData.netAllowance / props.incomeData1.netAllowance,
        netIncome: compareIncomeData.netIncome / props.incomeData1.netIncome,
    }

    return (
        <div className="d-flex col-lg-4">

            <IncomeDataColumn colWidth={4}
                              type={'currency'}
                              columnData={{
                                  ...compareIncomeData,
                                  header1: `Verschil`,
                                  header2: '2 t.o.v. 1'
                              }}
            />
            <IncomeDataColumn colWidth={8} borderRight={true} type={'percentage'}
                              columnData={{
                                  ...compareIncomeDataPercentage,
                                  header1: ``,
                                  header2: '% 2 t.o.v. 1'
                              }}
            />
        </div>

    );
};

export default CompareIncomeDataComponent;