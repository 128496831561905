export const maxRent23AndOver = 808.06;
export const maxRent23Under = 452.20;
const cappingLimit1Or2 = 647.19;
const cappingLimit3OrMore = 693.60;
const qualityDiscountLimit = 452.20;
const minRent = 225.54;

const singlePersonData = {
    factorA: 0.000000474433,
    factorB: 0.002448638402,
    minIncome: 19375,
}

const multiPersonData = {
    factorA: 0.000000279402,
    factorB: 0.001893212113,
    minIncome: 25225,
}

export const calculateBaseRent = (grossYearlyIncome: number, numberOfPeopleInHouseHold: number): number => {
    let factorA, factorB: number;
    if (numberOfPeopleInHouseHold === 1) {
        factorA = singlePersonData.factorA;
        factorB = singlePersonData.factorB;
    } else {
        factorA = multiPersonData.factorA;
        factorB = multiPersonData.factorB;
    }

    const baseRent = (factorA * (grossYearlyIncome ** 2)) + (factorB * grossYearlyIncome);
    return Math.max(baseRent, minRent);
};

// Function to calculate rent allowance
export const calculateRentAllowance = (rent: number, age: number, grossYearlyIncome: number, numberOfPeopleInHouseHold: number): number => {
    let allowance = 0;
    const baseRent = calculateBaseRent(grossYearlyIncome, numberOfPeopleInHouseHold);

    console.log(age);

    if (age < 23) {
        if (rent > maxRent23Under) return 0;
    }

    console.log(rent);

    if (rent > maxRent23AndOver) return 0;

    const cappingLimit = numberOfPeopleInHouseHold <= 2 ? cappingLimit1Or2 : cappingLimit3OrMore;

    if (rent <= qualityDiscountLimit) {
        allowance = rent - baseRent;
    } else if (rent <= cappingLimit) {
        allowance = qualityDiscountLimit + (rent - qualityDiscountLimit) * 0.65 - baseRent;
    } else {
        allowance = qualityDiscountLimit + (cappingLimit - qualityDiscountLimit) * 0.65 - baseRent;
    }

    console.log(allowance)

    if (allowance < 0) return 0;

    return allowance * 12;
};