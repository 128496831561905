export function formatPercentage(value: number) {
    if (isFinite(value)) {
        value = value * 100;
        return value.toFixed(2) + '<span>&nbsp;%</span>';
    } else {
        return '<span>-</span> <span>&nbsp;%</span>';
    }
}

export function formatCurrency(value: number) {
    if (isFinite(value)) {
        return '<span>€</span> <span>' + value.toLocaleString('nl-NL', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) + '</span>';

    } else {
        return '<span>€</span> <span>-</span>';
    }
}

export function removeTrailingNumber(str: string) {
    return str.replace(/-\d+$/, '');
}
