import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Footer from "./components/Footer";
import {HelmetProvider} from 'react-helmet-async';
import CookieBanner from "./pages/CookieBanner";


const App: React.FC = () => {
    return (
        <HelmetProvider>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/privacybeleid" element={<PrivacyPolicy/>}/>
                <Route path="*" element={<Home/>}/>
            </Routes>
            <Footer/>
            <CookieBanner/>
        </HelmetProvider>
    )

}

export default App;