import React, {FormEvent} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {PeriodIncomeData} from "./IncomeForm";
import {removeTrailingNumber} from "../utils/Utils";

interface Props {
    index: number;
    periodIncomeData: PeriodIncomeData;
    setPeriodIncomeData: (data: PeriodIncomeData) => void;
}

const PeriodIncomeForm: React.FC<Props> = (props) => {
    const handleChange = (e: FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        let value: string | number = target.value;
        let id = removeTrailingNumber(target.id);

        // Convert the string to a number for numeric fields
        if (id === "periodIncome" || id === "vacationPercentage" || id === "endOfYearBonus") {
            value = parseFloat(value);
        }

        props.setPeriodIncomeData({...props.periodIncomeData, [id]: value});
    };

    return (
        <form>
            <>
                {/* Monthly Salary */}
                <div className="mb-3">
                    <label htmlFor={`periodIncome-${props.index}`} className="form-label">(Bruto) Maandinkomen</label>
                    <div className="input-group mb-3">
                        <span className="input-group-text">€</span>
                        <input
                            type="number"
                            className="form-control"
                            id={`periodIncome-${props.index}`}
                            value={props.periodIncomeData.periodIncome}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                {/* Vacation Money */}
                <div className="mb-3">
                    <label className="form-label" htmlFor={`vacationPercentage-${props.index}`}>Vakantiegeld (Standaard
                        8%)</label>
                    <input
                        type="number"
                        className="form-control"
                        id={`vacationPercentage-${props.index}`}
                        value={props.periodIncomeData.vacationPercentage}
                        onChange={handleChange}
                    />
                </div>

                {/* 13th Month */}
                <div className="mb-3">
                    <label className="form-label" htmlFor={`endOfYearBonus-${props.index}`}>13de maand/Einde
                        jaarsbonus</label>
                    <div className="input-group mb-3">
                        <span className="input-group-text">€</span>
                        <input
                            type="number"
                            className="form-control"
                            id={`endOfYearBonus-${props.index}`}
                            value={props.periodIncomeData.endOfYearBonus}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </>
        </form>);
}

export default PeriodIncomeForm;