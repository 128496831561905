import React from 'react';
import {Line} from 'react-chartjs-2';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import incomeDataSet from '../assets/incomeDataSet.json';
import {IncomeData} from "../utils/Calculations";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const incomeData: IncomeData[] = incomeDataSet as IncomeData[];

const BrutoNettoGainGraph = () => {
    // Dummy data for demonstration purposes

    // const gainIncomeData = incomeData

    const grossYearlyIncome = incomeData.map(income => income.grossYearlyIncome);
    const netIncomeWithoutAllowanceGain = incomeData.map(income => income.netIncomeWithoutAllowanceGain);
    const netIncomeGain = incomeData.map(income => income.netIncomeGain);

    const data = {
        labels: grossYearlyIncome.map(income => `€${income}`),
        datasets: [
            {
                label: 'Toename Netto Inkomen (excl. toeslagen)',
                data: netIncomeWithoutAllowanceGain,
                borderColor: 'rgba(255, 99, 132, 1)',
                fill: false,
            },
            {
                label: 'Toename Netto Inkomen (incl. toeslagen)',
                data: netIncomeGain,
                borderColor: 'rgba(44, 255, 44, 1)',
                fill: false,
            }
        ],
    };

    const isMobile = window.innerWidth <= 1024;


    const options = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            x: {
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: isMobile ? 4 : 20
                },
            },
            // ... other options
        },
        legend: {
            display: false, // Hide on mobile
        },
        plugins: {
            legend: {
                position: 'top' as const,
                display: !isMobile,
            },
            title: {
                display: false,
            },
        },
    };


    return (
        <div>
            <h2>Extra netto inkomen per €1000,- extra bruto</h2>
            <Line data={data} options={options}/>
        </div>
    );
};

export default BrutoNettoGainGraph;
