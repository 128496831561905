import PeriodIncomeForm from "./PeriodIncomeForm";
import React, {useEffect, useState} from "react";
import HousingForm from "./HousingForm";
import {HousingData, HousingType, IncomeFormData} from "../utils/Calculations";

export interface PeriodIncomeData {
    period: 'monthly' | '4weeks' | 'week';
    periodIncome: number;
    vacationPercentage: number;
    endOfYearBonus: number;
}


interface Props {
    incomeFormData: IncomeFormData;
    setIncomeFormData: (data: IncomeFormData) => void;
    index: number;
    compare?: boolean;
    setCompare?: (compare: boolean) => void;
}

const IncomeForm: React.FC<Props> = (props) => {
    const [hasPeriodIncome, setHasPeriodIncome] = useState<boolean>(false); // monthly or yearly
    const [housingType, setHousingType] = useState<HousingType>('neither'); // New state for housing status

    const defaultHousingData: HousingData = ({
        type: 'neither',
        age: 25,
        rent: 500,
        numberOfPeopleInHouseHold: 1,
        eligible: true
    });

    const isRentSet = () => {
        return !!(props.incomeFormData.housingData.rent &&
            props.incomeFormData.housingData.age &&
            props.incomeFormData.housingData.numberOfPeopleInHouseHold)
    }


    useEffect(() => {
        if (housingType === 'rent' && !isRentSet()) {
            props.setIncomeFormData({
                ...props.incomeFormData, housingData: defaultHousingData
            })
        }
    }, [housingType]);
    const handleHousingDataChange = (housingData: HousingData) => {
        props.setIncomeFormData({
            ...props.incomeFormData, housingData
        })
    }

    const defaultPeriodIncomeData: PeriodIncomeData = {
        period: 'monthly',
        periodIncome: 0,
        vacationPercentage: 8,
        endOfYearBonus: 0
    };


    const [periodIncomeData, setPeriodIncomeData] = useState<PeriodIncomeData>(
        () => JSON.parse(localStorage.getItem(`periodIncomeData-${props.index}`)!) || defaultPeriodIncomeData
    );

    const getYearlyIncomeWithoutExtras = () => {
        if (periodIncomeData.period === 'monthly') {
            return periodIncomeData.periodIncome * 12;
        }

        if (periodIncomeData.period === '4weeks') {
            return periodIncomeData.periodIncome * 13;
        }

        if (periodIncomeData.period === 'week') {
            return periodIncomeData.periodIncome * 52;
        }

        return 0;
    }

    const reset = () => {
        setPeriodIncomeData(defaultPeriodIncomeData);

        props.setIncomeFormData({
            ...props.incomeFormData, grossYearlyIncome: 0, housingData: defaultHousingData
        });
    }

    useEffect(() => {
        const yearlyIncomeWithoutExtras = getYearlyIncomeWithoutExtras();

        let grossYearlyIncome = yearlyIncomeWithoutExtras;
        grossYearlyIncome += yearlyIncomeWithoutExtras * (periodIncomeData.vacationPercentage / 100);

        grossYearlyIncome += periodIncomeData.endOfYearBonus;

        if (isNaN(grossYearlyIncome)) {
            grossYearlyIncome = 0;
        }
        props.setIncomeFormData({
            ...props.incomeFormData, grossYearlyIncome
        });
    }, [periodIncomeData]);

    useEffect(() => {
        localStorage.setItem(`periodIncomeData-${props.index}`, JSON.stringify(periodIncomeData));
    }, [periodIncomeData, props.index]);


    return (
        <div className="input-group d-flex flex-column">
            <div className="d-flex justify-content-between flex-wrap mb-3">
                <h2>Inkomen {props.index}</h2>
                <div>
                    <button className="btn btn-outline-warning" onClick={reset}>Reset</button>
                    {props.index === 2 && <button className="btn btn-outline-secondary ms-2"
                                                  onClick={() => props.setCompare!(false)}>X</button>}
                </div>
            </div>
            <div className="w-100">
                <div>
                    <div className="d-flex justify-content-start gap-2">
                        <label htmlFor={`yearlySalaryField-${props.index}`} className="form-label">(Bruto)
                            Jaarinkomen</label>
                        <a className="link-primary" data-bs-toggle="collapse" href={`#period-data-${props.index}`}
                           role="button"
                           aria-expanded="false" aria-controls={`period-data-${props.index}`}
                           onClick={() => setHasPeriodIncome(!hasPeriodIncome)}>
                            <i className="bi bi-calculator-fill"></i>
                        </a>
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text">€</span>
                        <input
                            aria-describedby="basic-addon3"
                            type="number"
                            className="form-control"
                            disabled={hasPeriodIncome}
                            id={`yearlySalaryField-${props.index}`}
                            value={props.incomeFormData.grossYearlyIncome}
                            onChange={(e) =>
                                props.setIncomeFormData({
                                    ...props.incomeFormData,
                                    grossYearlyIncome: parseFloat(e.target.value)
                                })}
                        />
                    </div>

                </div>
                <div className="collapse" id={`period-data-${props.index}`}>
                    <div className="card card-body mb-3">
                        <PeriodIncomeForm periodIncomeData={periodIncomeData} setPeriodIncomeData={setPeriodIncomeData}
                                          index={props.index}/>
                        <a className="btn btn-primary" data-bs-toggle="collapse" href={`#period-data-${props.index}`}
                           role="button"
                           onClick={() => setHasPeriodIncome(!hasPeriodIncome)}
                           aria-expanded="false" aria-controls={`period-data-${props.index}`}>
                            Opslaan
                        </a>
                    </div>
                </div>

                <HousingForm
                    housingData={props.incomeFormData.housingData}
                    setHousingData={handleHousingDataChange}
                    index={props.index}
                />
            </div>
        </div>
    )
}

export default IncomeForm;