import React from 'react';
import {Link} from "react-router-dom";

const App: React.FC = () => {
    return (
        <div className="container mt-5">
            <div className="row mb-3">
                <div className="col">
                    <Link to="/" className="btn btn-primary">← Terug naar rekentool</Link>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <h1>Privacybeleid</h1>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col">
                    <h2>1. Inleiding</h2>
                    <p>Welkom op de privacy pagina van InkomenBerekenen.nl. Wij respecteren uw privacy en zorgen ervoor
                        dat de persoonlijke informatie die u met ons deelt vertrouwelijk wordt behandeld. In dit
                        privacybeleid leest u welke gegevens we verzamelen wanneer u onze website gebruikt en hoe we
                        deze gegevens gebruiken.</p>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col">
                    <h2>2. Gegevensverzameling</h2>
                    <p>We verzamelen gegevens om beter te begrijpen hoe bezoekers onze website gebruiken en om hen beter
                        van dienst te kunnen zijn.</p>
                    <strong>Google Analytics:</strong>
                    <p>We maken gebruik van Google Analytics om anonieme informatie te verzamelen over hoe gebruikers de
                        website gebruiken. Dit helpt ons de website te verbeteren. Google Analytics verzamelt informatie
                        over de pagina's die u bezoekt, hoelang u op de website blijft, hoe u op onze website terecht
                        bent gekomen en op welke links u klikt. We hebben Google Analytics zo ingesteld dat IP-adressen
                        anoniem worden gemaakt.</p>
                    <strong>Google Search Console & Google Ads:</strong>
                    <p>We maken ook gebruik van Google Search Console en Google Ads om het verkeer naar onze website te
                        monitoren en onze online reclamecampagnes te optimaliseren.</p>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col">
                    <h2>3. Gegevens delen</h2>
                    <p>We delen uw persoonlijke gegevens niet met derden zonder uw toestemming, tenzij dit wettelijk
                        verplicht of toegestaan is.</p>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col">
                    <h2>4. Cookies</h2>
                    <p>We maken gebruik van cookies om de functionaliteit van onze website te verbeteren en om uw
                        voorkeuren te onthouden. U kunt uw browser zo instellen dat u geen cookies accepteert. Het is
                        mogelijk dat sommige functies op onze website dan niet correct werken.</p>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col">
                    <h2>5. Wijzigingen</h2>
                    <p>We kunnen dit privacybeleid van tijd tot tijd wijzigen. Raadpleeg deze pagina regelmatig om op de
                        hoogte te blijven van de laatste wijzigingen.</p>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col">
                    <h2>6. Contact</h2>
                    <p>Als u vragen heeft over ons privacybeleid of de gegevens die we over u verzamelen, kunt u contact
                        met ons opnemen via [uw contactinformatie].</p>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col">
                    <Link to="/" className="btn btn-primary">← Terug naar rekentool</Link>
                </div>
            </div>
        </div>
    );
}

export default App;