const singleBaseAllowance = 1749;
const partnerBaseAllowance = 3498;
const baseIncome = 22356;
const singlePremiumPercentage = 0.01848;
const partnerPremiumPercentage = 0.04225;

const getRequiredPremium = (income: number, allowancePartner: boolean): number => {
    const premiumPercentage = allowancePartner ? partnerPremiumPercentage : singlePremiumPercentage;
    return premiumPercentage * baseIncome + 0.1361 * Math.max((income - baseIncome), 0);
}
export const calculateHealthcareAllowance = (incomeIncludingPartner: number, allowancePartner: boolean): number => {
    const baseAllowance = allowancePartner ? partnerBaseAllowance : singleBaseAllowance;
    return Math.max(baseAllowance - getRequiredPremium(incomeIncludingPartner, allowancePartner), 0); // Return the yearly amount
};