import React from "react";
import {IncomeData} from "../utils/Calculations";
import IncomeDataColumn, {ColumnData} from "./IncomeDataColumn";

interface Props {
    incomeDataNumber: number;
    incomeData: IncomeData;
    incomeDataLabels: ColumnData;
}

const IncomeDataComponent: React.FC<Props> = (props) => {

    const incomeDataPercentage = {
        grossYearlyIncome: 1,
        grossTax: props.incomeData.grossTax / props.incomeData.grossYearlyIncome,
        generalTaxCredit: props.incomeData.generalTaxCredit / props.incomeData.grossYearlyIncome,
        laborTaxCredit: props.incomeData.laborTaxCredit / props.incomeData.grossYearlyIncome,
        unusedTaxCredit: props.incomeData.unusedTaxCredit / props.incomeData.grossYearlyIncome,
        netTax: props.incomeData.netTax / props.incomeData.grossYearlyIncome,
        netIncomeWithoutAllowance: props.incomeData.netIncomeWithoutAllowance / props.incomeData.grossYearlyIncome,
        healthcareAllowance: (props.incomeData.healthcareAllowance ?? 0) / props.incomeData.grossYearlyIncome,
        rentAllowance: (props.incomeData.rentAllowance ?? 0) / props.incomeData.grossYearlyIncome,
        netAllowance: props.incomeData.netAllowance / props.incomeData.grossYearlyIncome,
        netIncome: props.incomeData.netIncome / props.incomeData.grossYearlyIncome,
    }

    return (
        <div className="d-flex col-lg-4">

            <IncomeDataColumn colWidth={5}
                              type={'currency'}
                              columnData={{
                                  ...props.incomeData,
                                  header1: `Inkomen ${props.incomeDataNumber}`,
                                  header2: 'Bedrag'
                              }}
            />
            <IncomeDataColumn colWidth={7} borderRight={true} type={'percentage'}
                              columnData={{
                                  ...incomeDataPercentage,
                                  header1: ``,
                                  header2: '% bruto inkomen'
                              }}
            />
        </div>

    );
};

export default IncomeDataComponent;