import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";

const CookieBanner: React.FC = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        // Controleer bij het laden van de component of de cookiebanner al eerder is geaccepteerd.
        const hasAcceptedCookies = localStorage.getItem('hasAcceptedCookies');
        if (!hasAcceptedCookies) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        setIsVisible(false);
        localStorage.setItem('hasAcceptedCookies', 'true');
    };

    if (!isVisible) return null;

    return (
        <div className="fixed-bottom p-3 bg-light border-top">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        Wij gebruiken cookies om de gebruikerservaring te verbeteren en voor analytische doeleinden.
                        Lees ons <Link to="/privacybeleid">privacybeleid</Link> voor meer informatie.
                    </div>
                    <button className="btn btn-primary" onClick={handleAccept}>
                        Accepteren
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CookieBanner;