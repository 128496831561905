import React, {useEffect} from "react";
import {formatCurrency, formatPercentage} from "../utils/Utils";

interface Props {
    colWidth: number;
    columnData: ColumnData;
    type?: 'currency' | 'percentage' | 'string';
    borderLeft?: boolean;
    borderRight?: boolean;
}

export interface ColumnData {
    header1: string;
    header2: string;
    grossYearlyIncome: number | string;
    grossTax: number | string;
    generalTaxCredit: number | string,
    laborTaxCredit: number | string,
    unusedTaxCredit: number | string,
    netTax: number | string,
    netIncomeWithoutAllowance: number | string,
    healthcareAllowance?: number | string,
    rentAllowance?: number | string,
    netAllowance: number | string,
    netIncome: number | string,
}

const IncomeDataColumn: React.FC<Props> = (props) => {

    const borderClasses = `${
        props.borderLeft ? 'border-start' : ''
    } ${
        props.borderRight ? 'border-end' : ''
    } ${
        (props.borderLeft || props.borderRight) ? 'border-black' : ''
    }`;


    const sharedClasses = `p-2 ${borderClasses}`.trim();

    const getContentFromNumber = (amount: number | string) => {
        if (typeof amount === 'string') {
            return amount;
        }
        if (props.type === 'currency') {
            return ('<div class="d-flex justify-content-between">' + formatCurrency(amount) + '</div>');
        }
        if (props.type === 'percentage') {
            return ('<div class="d-flex justify-content-end">' + formatPercentage(amount) + '</div>');
        }
        return '' + amount;
    }

    return (
        <div className={`d-flex flex-column col col-lg-${props.colWidth} text-nowrap`}>
            <div className={`${sharedClasses} bg-primary text-light`} dangerouslySetInnerHTML={
                {__html: (props.columnData.header1.length === 0 ? '&nbsp;' : props.columnData.header1)}}>
            </div>
            <div className={`${sharedClasses} bg-primary text-light`}>
                {props.columnData.header2}
            </div>
            <div className={`${sharedClasses} bg-primary-subtle`}
                 dangerouslySetInnerHTML={{__html: getContentFromNumber(props.columnData.grossYearlyIncome)}}>
            </div>
            <div className={`${sharedClasses} bg-light`}
                 dangerouslySetInnerHTML={{__html: getContentFromNumber(props.columnData.grossTax)}}>
            </div>
            <div className={`${sharedClasses} bg-light`}
                 dangerouslySetInnerHTML={{__html: getContentFromNumber(props.columnData.generalTaxCredit)}}>
            </div>
            <div className={`${sharedClasses} bg-light`}
                 dangerouslySetInnerHTML={{__html: getContentFromNumber(props.columnData.laborTaxCredit)}}>
            </div>
            <div className={`${sharedClasses} bg-light`}
                 dangerouslySetInnerHTML={{__html: getContentFromNumber(props.columnData.unusedTaxCredit)}}>
            </div>
            <div className={`${sharedClasses} bg-light`}
                 dangerouslySetInnerHTML={{__html: getContentFromNumber(props.columnData.netTax)}}>
            </div>
            <div className={`${sharedClasses} bg-primary-subtle`}
                 dangerouslySetInnerHTML={{__html: getContentFromNumber(props.columnData.netIncomeWithoutAllowance)}}>
            </div>
            <div className={`${sharedClasses}  bg-light`}
                 dangerouslySetInnerHTML={{__html: getContentFromNumber(props.columnData.healthcareAllowance!)}}>
            </div>
            <div className={`${sharedClasses} bg-light`}
                 dangerouslySetInnerHTML={{__html: getContentFromNumber(props.columnData.rentAllowance!)}}>
            </div>
            <div className={`${sharedClasses} bg-light`}
                 dangerouslySetInnerHTML={{__html: getContentFromNumber(props.columnData.netAllowance)}}>
            </div>
            <div className={`${sharedClasses} bg-primary-subtle`}
                 dangerouslySetInnerHTML={{__html: getContentFromNumber(props.columnData.netIncome)}}>
            </div>
        </div>
    )
};

export default IncomeDataColumn;