// App.tsx
import React, {useEffect, useState} from 'react';
import {calculateIncomeData, IncomeData, IncomeFormData} from "../utils/Calculations";
import BrutoNettoGraph from "../components/BrutoNettoGraph";
import BrutoNettoGainGraph from "../components/BrutoNettoGainGraph";
import IncomeForm from "../components/IncomeForm";
import IncomeDataColumn, {ColumnData} from "../components/IncomeDataColumn";
import IncomeDataComponent from "../components/IncomeDataComponent";
import CompareIncomeDataComponent from "../components/CompareIncomeDataComponent";
import {Helmet} from 'react-helmet-async';

const Home: React.FC = () => {
    const [compare, setCompare] = useState(JSON.parse(localStorage.getItem('compare')!) || false);

    const defaultFormData: IncomeFormData = {
        grossYearlyIncome: 0,
        grossYearlyIncomePartner: 0,
        allowancePartner: false,
        housingData: {
            type: 'neither',
            age: 25,
            rent: 500,
            numberOfPeopleInHouseHold: 1,
            eligible: true
        },
    };

    const [incomeFormData1, setIncomeFormData1] = useState<IncomeFormData>(defaultFormData);
    const [incomeFormData2, setIncomeFormData2] = useState<IncomeFormData>(defaultFormData);
    const incomeDataLabels: ColumnData = {
        header1: "",
        header2: "Omschrijving",
        grossYearlyIncome: 'Bruto inkomen',
        grossTax: 'Bruto belasting',
        generalTaxCredit: 'Algemene heffingskorting',
        laborTaxCredit: 'Arbeidskorting',
        unusedTaxCredit: 'Ongebruikte kortingen',
        netTax: 'Netto belasting',
        netIncomeWithoutAllowance: 'Netto inkomen (excl. toeslagen)',
        healthcareAllowance: 'Zorgtoeslag',
        rentAllowance: 'Huurtoeslag',
        netAllowance: 'Netto toeslagen',
        netIncome: 'Netto inkomen (incl. toeslagen)'
    }

    const [incomeData1, setIncomeData1] = useState<IncomeData>({
        grossYearlyIncome: 0,
        grossTax: 0,
        generalTaxCredit: 0,
        laborTaxCredit: 0,
        unusedTaxCredit: 0,
        netTax: 0,
        netIncomeWithoutAllowance: 0,
        healthcareAllowance: 0,
        rentAllowance: 0,
        netAllowance: 0,
        netIncome: 0,
    })

    const [incomeData2, setIncomeData2] = useState<IncomeData>({
        grossYearlyIncome: 0,
        grossTax: 0,
        generalTaxCredit: 0,
        laborTaxCredit: 0,
        unusedTaxCredit: 0,
        netTax: 0,
        netIncomeWithoutAllowance: 0,
        healthcareAllowance: 0,
        rentAllowance: 0,
        netAllowance: 0,
        netIncome: 0,
    });

    useEffect(() => {
        localStorage.setItem(`compare`, JSON.stringify(compare));
    }, [compare]);


    useEffect(() => {
        const incomeData = calculateIncomeData(incomeFormData1);
        setIncomeData1({...incomeData});
    }, [incomeFormData1]);

    useEffect(() => {
        const incomeData = calculateIncomeData(incomeFormData2);
        setIncomeData2({...incomeData});
    }, [incomeFormData2]);

    const downloadJSON = () => {
        const incomeDataSet: IncomeData[] = [];
        const incomeStepSize = 1000;
        for (let grossYearlyIncome = 0; grossYearlyIncome < 100000; grossYearlyIncome += incomeStepSize) {
            const incomeFormDataSet: IncomeFormData = {
                grossYearlyIncome: grossYearlyIncome,
                grossYearlyIncomePartner: 0,
                allowancePartner: false,
                housingData: {
                    type: 'rent',
                    age: 30,
                    rent: 808.06,
                    numberOfPeopleInHouseHold: 3,
                    eligible: true
                }
            }

            const incomeData = calculateIncomeData(incomeFormDataSet);

            if (grossYearlyIncome === 0) {
                incomeDataSet.push(incomeData);
                continue;
            }

            const grossYearlyIncomeGain = incomeStepSize;
            const netIncomeWithoutAllowanceGain = incomeData.netIncomeWithoutAllowance - incomeDataSet[(grossYearlyIncome - incomeStepSize) / 1000].netIncomeWithoutAllowance;
            const netIncomeGain = incomeData.netIncome - incomeDataSet[(grossYearlyIncome - incomeStepSize) / 1000].netIncome;

            incomeDataSet.push({
                ...incomeData,
                grossYearlyIncomeGain,
                netIncomeWithoutAllowanceGain,
                netIncomeGain
            });
        }
        const json = JSON.stringify(incomeDataSet, null, 2);
        const blob = new Blob([json], {type: 'application/json'});
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = 'incomeDataSet.json';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            <Helmet>
                <script async
                        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3221393665954492"
                        crossOrigin="anonymous"></script>
            </Helmet>

            <div className="bg-body-tertiary pt-md-2 pb-md-2 d-flex justify-content-center">
                <div className=" bg-body rounded shadow col-md-10 p-2 p-lg-4 mw-100">
                    <div>
                        <h1 className="pb-2">Bereken uw netto inkomen (2023)</h1>
                        <p>Gebruik dit hulpmiddel om het netto-inkomen te berekenen en te vergelijken op basis van
                            verschillende brutojaarinkomens. Voer de relevante gegevens in, en het hulpmiddel geeft
                            een
                            gedetailleerde uitsplitsing van het netto-inkomen, belastingen en andere relevante
                            informatie. <a href="https://docs.google.com/forms/d/e/1FAIpQLSdiryJGN595Q0rp5n4tzzcHtLh_f6mVzxzteqs6pezKGhJTGQ/viewform?usp=sf_link">Vraag of suggestie?</a></p>
                        {/*<button onClick={downloadJSON}>Download JSON</button>*/}

                        <div className="d-flex justify-content-between mb-3 gap-2">
                            <div className="col col-md-5 p-2 p-lg-3 card">
                                <IncomeForm incomeFormData={incomeFormData1}
                                            setIncomeFormData={setIncomeFormData1}
                                            index={1}/>
                            </div>
                            {compare ?
                                <div className="col col-md-5 p-2 p-lg-3 card">
                                    <IncomeForm incomeFormData={incomeFormData2}
                                                setIncomeFormData={setIncomeFormData2}
                                                index={2}
                                                compare={compare}
                                                setCompare={setCompare}
                                    />
                                </div>
                                :
                                <div
                                    className="col col-md-5 p-3 d-flex justify-content-center align-content-center">
                                    <button className="btn btn-primary" onClick={() => setCompare(true)}>Vergelijk
                                        met
                                        een ander inkomen
                                    </button>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="rounded mb-3">
                        <h2 className="pt-2 pb-2">Resultaten</h2>
                        <p><i>Er kunnen geen rechten ontleend worden aan de onderstaande data</i></p>
                        <div className="d-flex horizontal-scroll">
                            <IncomeDataColumn colWidth={3}
                                              borderRight={true}
                                              columnData={{
                                                  ...incomeDataLabels
                                              }}
                            />
                            <div className="d-flex col-lg-9 ">
                                <IncomeDataComponent incomeDataNumber={1} incomeData={incomeData1}
                                                     incomeDataLabels={incomeDataLabels}/>
                                {compare &&
                                    <>
                                        <IncomeDataComponent incomeDataNumber={2} incomeData={incomeData2}
                                                             incomeDataLabels={incomeDataLabels}/>
                                        <CompareIncomeDataComponent incomeData1={incomeData1}
                                                                    incomeData2={incomeData2}
                                                                    incomeDataLabels={incomeDataLabels}/>
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                    <BrutoNettoGraph/>
                    <BrutoNettoGainGraph/>
                </div>

            </div>
        </div>
    )
};

export default Home;
